<template>
  <vx-card :title="id === null ? $t('discipline_create') : $t('discipline_edit')">
    <PlanUsageAlert target="discipline" v-if="id === null"/>
    <vs-tabs>
      <vs-tab :label="$t('discipline')">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input
              :class="requiredClass(title)"
              :label="$t('classes_title')"
              name="title"
              v-model="title"
              v-validate="'required|max:191'"
            />
            <span class="text-danger text-sm" v-show="errors.has('title')">{{
              errors.first('title')
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6" v-permission="'courses.show'">
          <div class="vx-col w-1/2 md:w-4/6 sm:w-9/12 lg:w-10/12">
            <div @click="showOrgGrid = true">
              <vs-input
                class="w-full"
                disabled
                :label="$t('course')"
                name="course"
                v-model="courseName"
              />
              <span
                class="text-danger text-sm vx-col"
                v-show="errors.has('courseId')"
                >{{ errors.first('courseId') }}</span
              >
            </div>
          </div>
          <div class="vx-col w-1/2 md:w-2/6 sm:w-3/12 lg:w-2/12 flex items-end">
            <vs-button
              v-permission="'disciplines.course.search'"
              icon="search"
              class="w-full"
              type="border"
              @click="showCourseGrid = true"
              >{{ $t('selecionar') }}</vs-button
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              :label="$t('description')"
              name="description"
              v-model="description"
            />
            <span class="text-danger text-sm" v-show="errors.has('description')">{{
              errors.first('description')
            }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button
              class="float-right"
              color="primary"
              type="border"
              @click="cancel"
              >{{ $t('action.cancel') }}</vs-button
            >
            <vs-button
              v-permission="'disciplines.create'"
              :disabled="!validateForm"
              class="float-right mr-2"
              @click="create"
              >{{ $t('action.save') }}</vs-button
            >
          </div>
        </div>
      </vs-tab>
      <vs-tab :label="$t('acessos')" v-if="showAccessTab">
        <div class="vx-row w-full">
          <div class="vx-col w-full">
            <data-access-form modelType="disciplines" :modelId="id"/>
          </div>
        </div>
      </vs-tab>
    </vs-tabs>

    <vs-popup
      style="z-index: 20000000;"
      :title="$t('courses')"
      :active.sync="showCourseGrid"
      fullscreen="true"
    >
      <Grid
        :service="courseService"
        route_name="courses"
        hide_actions="true"
        :delegate="{itemSelected: () => handleCourseSelection} "
      >
        <template v-slot:gridThead>
          <vs-th key="id">{{ $t('codigo') }}</vs-th>
          <vs-th key="name">{{ $t('name') }}</vs-th>
          <vs-th key="description">{{ $t('description') }}</vs-th>
          <vs-th key="educationlevels">{{ $t('fields.education_level') }}</vs-th>
        </template>
        <template v-slot:gridData="props">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
            <vs-td width="10%" key="id">
              <div v-html-safe="tr.id"></div>
            </vs-td>
            <vs-td width="30%" key="name">
              <div v-html-safe="tr.name"></div>
            </vs-td>
            <vs-td width="45%" key="description">{{
              tr.description ? tr.description : ''
            }}</vs-td>
            <vs-td width="15%" key="educationlevels">{{
              tr.education_levels ? tr.education_levels.name : ''
            }}</vs-td>
          </vs-tr>
        </template>
      </Grid>
    </vs-popup>
  </vx-card>
</template>

<script>
import DisciplineService from '@/services/api/DisciplineService'
import CourseService from '@/services/api/CourseService'
import DataAccessForm from '@/components/DataAccessForm.vue'
import PlanUsageAlert from '@/components/finance/PlanUsageAlert'

export default {

  components: {
    DataAccessForm,
    PlanUsageAlert
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    inlineForm: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    id: null,
    title: '',
    description: '',
    courseName: '',
    courseId: null,
    service: null,
    courseService: null,
    showCourseGrid: false,
    key: 0
  }),
  watch: {
    activeInstitution() {
      this.key++
    }
  },
  computed: {
    validateForm() {
      return !this.isEmpty(this.title)
    },
    showAccessTab() {
      return !this.isEmpty(this.id) && this.$acl.hasPermission('data_access.role.manage')
    },
    activeInstitution() {
      return this.$store.getters['institution/active']
    },
  },
  methods: {
    create() {
      this.clearErrors(this.$validator)
      this.$vs.loading()

      this.service
        .createOrUpdate({
          id: this.id,
          title: this.title,
          course_id: this.courseId,
          description: this.description
        })
        .then(
          data => {
            this.$vs.loading.close()
            this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
            if (this.inlineForm) {
              this.$emit('created', data.id, data.title)
            } else {
              this.$router.push(`/disciplines/${data.id}/edit`)
            }
          },
          error => {
            this.$vs.loading.close()
            this.showErrors(this.$validator, error)
            this.notifyError(
              this.$vs,
              this.$t('nao-foi-possivel-realizar-esta-operacao')
            )
          }
        )
    },
    cancel() {
      if (this.inlineForm) {
        //Route from popup
        this.$emit('created')
      } else {
        this.$router.push('/disciplines')
      }
    },
    getData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        data => {
          this.$set(this.$data, 'id', data.id)
          this.$set(this.$data, 'title', data.title)
          this.$set(this.$data, 'courseId', data.course_id)
          this.$set(this.$data, 'description', data.description)
          if (!this.isEmpty(this.courseService) && this.courseId) {
            this.courseService.read(this.courseId).then(
              data => {
                this.$set(this.$data, 'courseName', data.name)
                this.$vs.loading.close()
              },
              error => {
                this.$vs.loading.close()
              }
            )
          }
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    handleCourseSelection(course) {
      if (course) {
        this.courseName = course.name
        this.courseId = course.id
        this.showCourseGrid = false
      }
    },
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    }
  },
  beforeMount() {
    this.service = DisciplineService.build(this.$vs)
    const acl = this.$acl
    if (acl.hasPermission('courses.show')) {
      this.courseService = CourseService.build(this.$vs)
    }
    if (this.id) {
      this.getData(this.id)
    }
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>
