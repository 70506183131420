var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    {
      attrs: {
        title:
          _vm.id === null
            ? _vm.$t("discipline_create")
            : _vm.$t("discipline_edit"),
      },
    },
    [
      _vm.id === null
        ? _c("PlanUsageAlert", { attrs: { target: "discipline" } })
        : _vm._e(),
      _c(
        "vs-tabs",
        [
          _c("vs-tab", { attrs: { label: _vm.$t("discipline") } }, [
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:191",
                        expression: "'required|max:191'",
                      },
                    ],
                    class: _vm.requiredClass(_vm.title),
                    attrs: { label: _vm.$t("classes_title"), name: "title" },
                    model: {
                      value: _vm.title,
                      callback: function ($$v) {
                        _vm.title = $$v
                      },
                      expression: "title",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("title"),
                          expression: "errors.has('title')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("title")))]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "courses.show",
                    expression: "'courses.show'",
                  },
                ],
                staticClass: "vx-row mb-6",
              },
              [
                _c(
                  "div",
                  { staticClass: "vx-col w-1/2 md:w-4/6 sm:w-9/12 lg:w-10/12" },
                  [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            _vm.showOrgGrid = true
                          },
                        },
                      },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            disabled: "",
                            label: _vm.$t("course"),
                            name: "course",
                          },
                          model: {
                            value: _vm.courseName,
                            callback: function ($$v) {
                              _vm.courseName = $$v
                            },
                            expression: "courseName",
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("courseId"),
                                expression: "errors.has('courseId')",
                              },
                            ],
                            staticClass: "text-danger text-sm vx-col",
                          },
                          [_vm._v(_vm._s(_vm.errors.first("courseId")))]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col w-1/2 md:w-2/6 sm:w-3/12 lg:w-2/12 flex items-end",
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "disciplines.course.search",
                            expression: "'disciplines.course.search'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: { icon: "search", type: "border" },
                        on: {
                          click: function ($event) {
                            _vm.showCourseGrid = true
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("selecionar")))]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      label: _vm.$t("description"),
                      name: "description",
                    },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("description"),
                          expression: "errors.has('description')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("description")))]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "float-right",
                      attrs: { color: "primary", type: "border" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(_vm._s(_vm.$t("action.cancel")))]
                  ),
                  _c(
                    "vs-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: "disciplines.create",
                          expression: "'disciplines.create'",
                        },
                      ],
                      staticClass: "float-right mr-2",
                      attrs: { disabled: !_vm.validateForm },
                      on: { click: _vm.create },
                    },
                    [_vm._v(_vm._s(_vm.$t("action.save")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm.showAccessTab
            ? _c("vs-tab", { attrs: { label: _vm.$t("acessos") } }, [
                _c("div", { staticClass: "vx-row w-full" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("data-access-form", {
                        attrs: { modelType: "disciplines", modelId: _vm.id },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticStyle: { "z-index": "20000000" },
          attrs: {
            title: _vm.$t("courses"),
            active: _vm.showCourseGrid,
            fullscreen: "true",
          },
          on: {
            "update:active": function ($event) {
              _vm.showCourseGrid = $event
            },
          },
        },
        [
          _c("Grid", {
            attrs: {
              service: _vm.courseService,
              route_name: "courses",
              hide_actions: "true",
              delegate: {
                itemSelected: function () {
                  return _vm.handleCourseSelection
                },
              },
            },
            scopedSlots: _vm._u([
              {
                key: "gridThead",
                fn: function () {
                  return [
                    _c("vs-th", { key: "id" }, [
                      _vm._v(_vm._s(_vm.$t("codigo"))),
                    ]),
                    _c("vs-th", { key: "name" }, [
                      _vm._v(_vm._s(_vm.$t("name"))),
                    ]),
                    _c("vs-th", { key: "description" }, [
                      _vm._v(_vm._s(_vm.$t("description"))),
                    ]),
                    _c("vs-th", { key: "educationlevels" }, [
                      _vm._v(_vm._s(_vm.$t("fields.education_level"))),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "gridData",
                fn: function (props) {
                  return _vm._l(props.data, function (tr, indextr) {
                    return _c(
                      "vs-tr",
                      { key: indextr, attrs: { data: tr } },
                      [
                        _c("vs-td", { key: "id", attrs: { width: "10%" } }, [
                          _c("div", {
                            directives: [
                              {
                                name: "html-safe",
                                rawName: "v-html-safe",
                                value: tr.id,
                                expression: "tr.id",
                              },
                            ],
                          }),
                        ]),
                        _c("vs-td", { key: "name", attrs: { width: "30%" } }, [
                          _c("div", {
                            directives: [
                              {
                                name: "html-safe",
                                rawName: "v-html-safe",
                                value: tr.name,
                                expression: "tr.name",
                              },
                            ],
                          }),
                        ]),
                        _c(
                          "vs-td",
                          { key: "description", attrs: { width: "45%" } },
                          [_vm._v(_vm._s(tr.description ? tr.description : ""))]
                        ),
                        _c(
                          "vs-td",
                          { key: "educationlevels", attrs: { width: "15%" } },
                          [
                            _vm._v(
                              _vm._s(
                                tr.education_levels
                                  ? tr.education_levels.name
                                  : ""
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }