var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c(
              "vs-button",
              {
                attrs: { icon: "add" },
                on: {
                  click: function ($event) {
                    _vm.showModal = true
                  },
                },
              },
              [_vm._v("Adicionar")]
            ),
            _c("vs-divider"),
            _c("Grid", {
              key: _vm.gridKey,
              ref: "dataAccessGrid",
              attrs: {
                service: _vm.dataAccessService,
                route_name: "data_accesses",
                route_grid_path: _vm.dataAccessesGridPath,
                hide_edit: "true",
                delegate: {
                  destroy: function () {
                    return _vm.removeRoleDataAccess
                  },
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "gridThead",
                  fn: function () {
                    return [
                      _c("vs-th", { key: "id" }, [_vm._v("ID")]),
                      _c("vs-th", { key: "role_name" }, [
                        _vm._v(_vm._s(_vm.$t("grupo"))),
                      ]),
                      _c("vs-th", { key: "role_description" }, [
                        _vm._v(_vm._s(_vm.$t("descricao-grupo"))),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "gridActions",
                  fn: function () {
                    return [
                      _c("vs-th", { key: "actions" }, [
                        _vm._v(_vm._s(_vm.$t("Actions"))),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "gridData",
                  fn: function (props) {
                    return _vm._l(props.data, function (tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: tr } },
                        [
                          _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                            _vm._v(_vm._s(tr.id)),
                          ]),
                          _c(
                            "vs-td",
                            { key: "role_name", attrs: { width: "45%" } },
                            [_vm._v(_vm._s(tr.owner && tr.owner.display_name))]
                          ),
                          _c(
                            "vs-td",
                            {
                              key: "role_description",
                              attrs: { width: "45%" },
                            },
                            [_vm._v(_vm._s(tr.owner && tr.owner.description))]
                          ),
                          _c(
                            "vs-td",
                            { key: "actions", attrs: { width: "5%" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "grid grid-cols-3 gap-8 sm:gap-4 md:gap-4",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-center",
                                    },
                                    [
                                      _c("feather-icon", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip.top",
                                            value: {
                                              content: "Excluir",
                                              delay: { show: 1000, hide: 100 },
                                            },
                                            expression:
                                              "{\n                      content: 'Excluir',\n                      delay: { show: 1000, hide: 100 }\n                    }",
                                            modifiers: { top: true },
                                          },
                                        ],
                                        attrs: {
                                          icon: "Trash2Icon",
                                          svgClasses:
                                            "h-5 w-5 mb-1 stroke-current text-danger ",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return props.destroy(
                                              props.data[indextr]
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "vs-popup",
        {
          attrs: { title: _vm.$t("grupos"), active: _vm.showModal },
          on: {
            "update:active": function ($event) {
              _vm.showModal = $event
            },
          },
        },
        [
          _vm.showModal
            ? _c("Grid", {
                attrs: {
                  service: _vm.roleService,
                  delegate: {
                    itemSelected: function () {
                      return _vm.addRoleDataAccess
                    },
                  },
                  route_name: "roles",
                  hide_actions: "true",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }