<template>
  <div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-button @click="showModal = true" icon="add">Adicionar</vs-button>
        <vs-divider />
        <Grid
          ref="dataAccessGrid"
          :key="gridKey"
          :service="dataAccessService"
          route_name="data_accesses"
          :route_grid_path="dataAccessesGridPath"
          hide_edit="true"
          :delegate="{destroy: () => removeRoleDataAccess}"
        >
          <template v-slot:gridThead>
            <vs-th key="id">ID</vs-th>
            <vs-th key="role_name">{{ $t('grupo') }}</vs-th>
            <vs-th key="role_description">{{ $t('descricao-grupo') }}</vs-th>
          </template>
          <template v-slot:gridActions>
            <vs-th key="actions">{{ $t('Actions') }}</vs-th>
          </template>
          <template v-slot:gridData="props">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
              <vs-td width="5%" key="id">{{ tr.id }}</vs-td>
              <vs-td width="45%" key="role_name">{{ tr.owner && tr.owner.display_name }}</vs-td>
              <vs-td width="45%" key="role_description">{{tr.owner && tr.owner.description}}</vs-td>
              <vs-td width="5%" key="actions">
                <div class="grid grid-cols-3 gap-8 sm:gap-4 md:gap-4">
                  <div class="flex items-center justify-center">
                    <feather-icon
                      icon="Trash2Icon"
                      svgClasses="h-5 w-5 mb-1 stroke-current text-danger "
                      @click="props.destroy(props.data[indextr])"
                      v-tooltip.top="{
                        content: 'Excluir',
                        delay: { show: 1000, hide: 100 }
                      }"
                    />
                  </div>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </Grid>
      </div>
    </div>

    <vs-popup :title="$t('grupos')" :active.sync="showModal">
      <Grid
        :service="roleService"
        :delegate="{itemSelected: () => addRoleDataAccess} "
        route_name="roles"
        hide_actions="true"
        v-if="showModal"
      ></Grid>
    </vs-popup>
  </div>
</template>

<script>
import RoleService from '@/services/api/RoleService'
import DataAccessService from '@/services/api/DataAccessService'

export default {
  props: {
    modelType: {
      type: String,
      default: null
    },
    modelId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    roleService: null,
    dataAccessService: null,
    showModal: false,
    gridKey: 0
  }),
  computed: {
    dataAccessesGridPath() {
      return `${this.model.type}/${this.model.id}/roles/grid`
    },
    model() {
      return {
        id: this.modelId,
        type: this.modelType
      }
    },
  },
  methods: {
    addRoleDataAccess(role) {
      if (role) {
        this.$vs.loading()
        this.dataAccessService
          .modelAddRole(role.id, this.model)
          .then(
            response => {
              this.$vs.loading.close()
              this.$vs.notify({
                title: 'Successo!',
                text: this.$t('operacao-realizada-com-sucesso'),
                color: 'success',
                iconPack: 'feather',
                position: 'top-center',
                icon: 'icon-check-circle'
              })

              this.gridKey += 1
            },
            error => {
              this.$vs.loading.close()
              this.notifyError(this.$vs, this.$t('nao-foi-possivel-incluir-este-grupo'))
            }
          )
      }
      this.showModal = false
    },
    removeRoleDataAccess(dataAccess) {
      this.$vs.loading()
      this.dataAccessService.modelRemoveRole(dataAccess.owner.id, this.model).then(
        response => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Successo!',
            text: this.$t('operacao-realizada-com-sucesso'),
            color: 'success',
            iconPack: 'feather',
            position: 'top-center',
            icon: 'icon-check-circle'
          })

          this.gridKey += 1
        },
        error => {
          this.$vs.loading.close()
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-remover-este-grupo'))
        }
      )
    }
  },

  beforeMount() {
    this.roleService = RoleService.build(this.$vs)
    this.dataAccessService = DataAccessService.build(this.$vs)
  }
}
</script>